// extracted by mini-css-extract-plugin
export var appSlider = "form-module--app-slider--1YxRI";
export var button = "form-module--button--9WFu7";
export var container = "form-module--container--3UmFp";
export var defaultContainer = "form-module--default-container--3HuBq";
export var error = "form-module--error--34n7y";
export var featureSlider = "form-module--feature-slider--1z4hO";
export var form = "form-module--form--3vmTR";
export var halfWidth = "form-module--half-width--Isdip";
export var innerPagesSlider = "form-module--inner-pages-slider--3hMnR";
export var leftAppSlide = "form-module--left-app-slide--2whxH";
export var logoSlider = "form-module--logo-slider--1oeQc";
export var phoneAppSelect = "form-module--phone-app-select--pDDFv";
export var phoneCaseSelect = "form-module--phone-case-select--31y5t";
export var rightAppSlide = "form-module--right-app-slide--1SXl6";
export var slickActive = "form-module--slick-active--2j7Ze";
export var slickArrow = "form-module--slick-arrow--3AfW-";
export var slickCenter = "form-module--slick-center--M6m1T";
export var slickDots = "form-module--slick-dots--1bIQv";
export var slickNext = "form-module--slick-next--2OAUy";
export var slickPrev = "form-module--slick-prev--3hZrZ";
export var slickSlide = "form-module--slick-slide--CZoIp";
export var slickSlider = "form-module--slick-slider--2wCbp";
export var slickTrack = "form-module--slick-track--2GTTd";
export var solvingSlider = "form-module--solving-slider--2sj-q";
export var success = "form-module--success--mSv7k";