import React from "react"
import { useMediaQuery } from "react-responsive"
import { replaceSpacing } from "../../../utils/functions"
import { animationSlideUp, animationFade } from "../../../utils/scroll-animation"
import Social from "../../../components/social/Social"
import Hints from "../../../common/hints/hints"
import arrow from "../../../images/scroll_down.png"
import scrollTo from "gatsby-plugin-smoothscroll"
import InquirerForm from "../../../components/inquirer_form/inquirer_form"

export default function HeroWithForm(props) {
  const { hero, styles, background, nextSection, hintsFontSize, heroFormContent } = props
  // const Svg = { __html: hero?.svgUpload }
  // const isColumnDisplay = useMediaQuery({ maxWidth: 1170 })

  return (
    <section
      className={styles.hero}
      style={{
        backgroundColor: hero?.backgroundColor ? hero.backgroundColor.hex : "",
      }}
    >
      <div className={`default-container ${styles.heroWrapper}`}>
        <div className={styles.heroContainer}>
          <div className={styles.heroHeader}>
            <div className={styles.contentWrapper}>
              <div className={styles.titleWrapper}>
                <h1 style={{ whiteSpace: "pre-wrap" }} {...animationSlideUp()}>
                  {replaceSpacing(hero.title)}
                </h1>
                <Hints
                  hintsFontSize={hintsFontSize}
                  hints={hero?.hints}
                  isAnimation={hero?.isHintsAnimation}
                />
              </div>
              <div className={styles.formContent}
                {...animationFade()}>
                  <InquirerForm content={heroFormContent} />
              </div>
            </div>
          </div>
          <div className={styles.heroFooter}>
            <div className={styles.heroSocial}>
              <Social displayClass="tabletSocial" />
            </div>
            {/* <div
              onClick={() => scrollTo(`#${nextSection}`, "nearest")}
              className={styles.heroArrow}
            >
              <img src={arrow}></img>
            </div> */}
            <div className={styles.heroFloatingBtn}>
              {/* <FloatBtn displayClass="tabletFloatingBtn" /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
