import React from "react"
import Clients from "../../components/clients/clients"
import {
  animationSlideRight,
  animationFade,
} from "../../utils/scroll-animation"
import Hints from "../../common/hints/hints"

export default function RecentWork(props) {
  const { works, styles, headerCenter} = props

  // console.log(works)
  const returnTitle = () => {
    return works?.title && <h1 {...animationSlideRight()}>{works.title}</h1>
  }

  const returnClients = () => {
    return (
      works?.clients.length > 0 &&
      works?.clients.map((client, index) => {
        let clients = {
          case_card: client.case_card,
          slug: client.case_card.slug,
        }
        return <Clients key={index + "case_card"} client={clients} />
      })
    )
  }

  return (
    <section className={styles.works}>
      <div className="default-container">
        <div className={styles.worksContainer}>
          <div className={styles.titleWrapper} style={{
            textAlign: headerCenter
              ? "center"
              : "",
            }}
          >
            {returnTitle()}
            <Hints
              hints={works?.hints}
              isAnimation={works?.isHintsAnimation}
              typingBackground={
                works?.backgroundColor ? works.backgroundColor.hex : "#673895"
              }
            />
          </div>
          <div className={styles.contentWrapper} {...animationFade()}>
            <div className={styles.content}>
              {/* <Clients props={works.clients} /> */}
              {returnClients()}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
