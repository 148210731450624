// extracted by mini-css-extract-plugin
export var appSlider = "works_index-module--app-slider--2kFxo";
export var content = "works_index-module--content--2K16g";
export var contentWrapper = "works_index-module--content-wrapper--1xKdx";
export var defaultContainer = "works_index-module--default-container--2FE-R";
export var featureSlider = "works_index-module--feature-slider--1Hf02";
export var innerPagesSlider = "works_index-module--inner-pages-slider--14szz";
export var leftAppSlide = "works_index-module--left-app-slide--2LyHj";
export var logoSlider = "works_index-module--logo-slider--2i_YH";
export var phoneAppSelect = "works_index-module--phone-app-select--15qEi";
export var phoneCaseSelect = "works_index-module--phone-case-select--1Whdq";
export var rightAppSlide = "works_index-module--right-app-slide--2HpMc";
export var slickActive = "works_index-module--slick-active--2OkEX";
export var slickArrow = "works_index-module--slick-arrow--1lA0s";
export var slickCenter = "works_index-module--slick-center--1K-C2";
export var slickDots = "works_index-module--slick-dots--2c9rp";
export var slickNext = "works_index-module--slick-next--1GWEm";
export var slickPrev = "works_index-module--slick-prev--2IXa_";
export var slickSlide = "works_index-module--slick-slide--3RlwD";
export var slickSlider = "works_index-module--slick-slider--3R443";
export var slickTrack = "works_index-module--slick-track--ciOUC";
export var solvingSlider = "works_index-module--solving-slider--7P9Xt";
export var titleWrapper = "works_index-module--title-wrapper--1UdUM";
export var works = "works_index-module--works--26oRl";
export var worksContainer = "works_index-module--works-container--1gnUF";