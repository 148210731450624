import { graphql, useStaticQuery } from "gatsby"

export default function FitnessAppData() {
  return useStaticQuery(graphql`
    query queryFitnessApp {
      seo: sanitySeo(slug: { current: { eq: "fitness-app" } }) {
        meta_title
        meta_description
        keywords
        meta_image {
          asset {
            url
          }
        }
        alt_tag
      }
      hero: sanityHero(slug: { current: { eq: "fitness-app" } }) {
        title
        hints {
          parts
        }
        svgUpload
        isHintsAnimation
        backgroundColor {
          hex
        }
      }
      features2: sanityFeatures(
        slug: { current: { eq: "benifits-of-building-health" } }
      ) {
        features {
          backgroundColor {
            hex
          }
          title
          icon {
            asset {
              url
            }
          }
          info
        }
        id
        hints {
          parts
        }
        title
        slide
        backgroundColor {
          hex
        }
        isHintsAnimation
      }
      works: sanityWorks(slug: { current: { eq: "work-fitness-app" } }) {
        title
        hints {
          parts
        }
        clients {
          case_card {
            slug {
              current
            }
            logo {
              asset {
                url
              }
            }
            name
            description
            technologies {
              name
              logo {
                asset {
                  url
                }
              }
            }
          }
        }
        backgroundColor {
          hex
        }
        isHintsAnimation
      }
      action: sanityCallAction(
        slug: { current: { eq: "request-mockup-fitness-app" } }
      ) {
        title
        button
        description
        image {
          asset {
            url
          }
        }
        background {
          asset {
            url
          }
        }
        backgroundColor {
          hex
        }
        target
      }
      testimonial: sanityTestimonials(slug: { current: { eq: "dark" } }) {
        title
        testimonials {
          author {
            name
            position
            image {
              asset {
                url
              }
            }
          }
          text
        }
        shadowBackgroundColor {
          hex
        }
        cardBackgroundColor {
          hex
        }
        backgroundColor {
          hex
        }
      }
      appSlides: sanitySlides(slug: {current: {eq: "app-slides-fitness-app"}}) {
        appSlides {
          appImage {
            asset {
              url
            }
          }
          appInfo
          appName
        }
        hints {
          parts
        }
        backgroundColor {
          hex
        }
        title
      }
      faq: sanityFaq(slug: { current: { eq: "fitness-app-faq" } }) {
        title
        backgroundColor {
          hex
        }
        questions {
          response
          faq
          id
        }
      }
    }
  `)
}
