import React from "react"
import ListItem from "../../components/listItem/listItem"
import { animationSlideUp, animationSlideRight, animationSlideLeft, animationFade, animationZoomIn } from "../../utils/scroll-animation"

export default function Faq(props) {
  const { faq, styles } = props

  return (
    <section style={{backgroundColor: faq?.backgroundColor ? faq.backgroundColor.hex : ""}}
      className={styles.faq}
    >
      <div className="default-container">
        <div className={styles.faqContainer}>
            <div className={styles.titleWrapper} {...animationSlideUp()}>
              <h1 className={styles.title}>{faq.title}</h1>
            </div>
            <div className={styles.contentWrapper} {...animationFade()}>
              <div className={styles.content}>
                {faq.questions?.map((item, index) => (
                  <ListItem key={index} question={item} />
                ))}
              </div>
            </div>
        </div>
      </div>
    </section>
  )
}
