// extracted by mini-css-extract-plugin
export var appSlider = "app_slides-module--app-slider--3CLRI";
export var appSlides = "app_slides-module--app-slides--2WD1Y";
export var appSlidesContainer = "app_slides-module--app-slides-container--3B-dS";
export var contentWrapper = "app_slides-module--content-wrapper--1Xujk";
export var defaultContainer = "app_slides-module--default-container--3qPEf";
export var featureSlider = "app_slides-module--feature-slider--1KylX";
export var innerPagesSlider = "app_slides-module--inner-pages-slider--scAHn";
export var leftAppSlide = "app_slides-module--left-app-slide--24znK";
export var logoSlider = "app_slides-module--logo-slider---oy2B";
export var phoneAppSelect = "app_slides-module--phone-app-select--1gQnQ";
export var phoneCaseSelect = "app_slides-module--phone-case-select--27nk5";
export var rightAppSlide = "app_slides-module--right-app-slide--2muSQ";
export var slickActive = "app_slides-module--slick-active--9HmEA";
export var slickArrow = "app_slides-module--slick-arrow--2NUkR";
export var slickCenter = "app_slides-module--slick-center--1_WXC";
export var slickDots = "app_slides-module--slick-dots--aK3hE";
export var slickNext = "app_slides-module--slick-next--3xVue";
export var slickPrev = "app_slides-module--slick-prev--wCz65";
export var slickSlide = "app_slides-module--slick-slide--1qud1";
export var slickSlider = "app_slides-module--slick-slider--OqA6T";
export var slickTrack = "app_slides-module--slick-track--1spU4";
export var slides = "app_slides-module--slides--20YCi";
export var solvingSlider = "app_slides-module--solving-slider--2kdZJ";
export var subtitle = "app_slides-module--subtitle--2QGR3";
export var title = "app_slides-module--title--23Ypx";
export var titleInfo = "app_slides-module--title-info--1N8qf";
export var titleWrapper = "app_slides-module--title-wrapper--Uf4Wl";