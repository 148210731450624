import React from "react"
import { Link } from "gatsby"
import * as styles from "./clients.module.scss"

export default function Clients(props) {
  const { client, cardWidth } = props
  const clientItem = client.case_card

  const returnContent = client => {
    return (
      (client?.logo || client?.description) && (
        <div className={styles.content}>
          {client?.logo && <img src={client.logo.asset.url} />}
          {client?.description && <p>{client.description}</p>}
        </div>
      )
    )
  }

  const returnTechnologies = client => {
    return (
      client?.technologies.length > 0 && (
        <div className={styles.techs}>
          {client.technologies.map((tech, index) => (
            <img key={index + "-tech"} src={tech.logo.asset.url} />
          ))}
        </div>
      )
    )
  }

  return (
    <Link
      to={`/case-study/${client?.slug.current}`}
      className={styles.clientsItem}
      style={{ width: cardWidth ? cardWidth : "" }}
    >
      <div className={styles.head}>
        {clientItem?.name && <h2>{clientItem.name}</h2>}
        {returnContent(clientItem)}
      </div>
      {returnTechnologies(clientItem)}
    </Link>
  )
}
