// extracted by mini-css-extract-plugin
export var appSlider = "app_slide_item-module--app-slider--3wt3e";
export var content = "app_slide_item-module--content--2UTn7";
export var defaultContainer = "app_slide_item-module--default-container--1F-gh";
export var featureSlider = "app_slide_item-module--feature-slider--ixGag";
export var innerPagesSlider = "app_slide_item-module--inner-pages-slider--Gkzt_";
export var leftAppSlide = "app_slide_item-module--left-app-slide--32jcg";
export var logoSlider = "app_slide_item-module--logo-slider--1Tmfi";
export var phoneApp = "app_slide_item-module--phone-app--3pFwg";
export var phoneAppSelect = "app_slide_item-module--phone-app-select--l5vqx";
export var phoneCase = "app_slide_item-module--phone-case--2DnbR";
export var phoneCaseSelect = "app_slide_item-module--phone-case-select--2MGx8";
export var rightAppSlide = "app_slide_item-module--right-app-slide--1wftF";
export var singleDisplay = "app_slide_item-module--single-display--2P8pO";
export var slickActive = "app_slide_item-module--slick-active--2PypL";
export var slickArrow = "app_slide_item-module--slick-arrow--2sc13";
export var slickCenter = "app_slide_item-module--slick-center--sDINS";
export var slickDots = "app_slide_item-module--slick-dots--2BarL";
export var slickNext = "app_slide_item-module--slick-next--3WJ3U";
export var slickPrev = "app_slide_item-module--slick-prev--Xo2AM";
export var slickSlide = "app_slide_item-module--slick-slide--3NluE";
export var slickSlider = "app_slide_item-module--slick-slider--13MC9";
export var slickTrack = "app_slide_item-module--slick-track--2AB_r";
export var solvingSlider = "app_slide_item-module--solving-slider--CKUMd";