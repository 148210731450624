import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Layout from "../components/Layout"
import HeroWithForm from "../sections/hero/hero_with_form/hero_with_form"
import ClientExp from "../sections/clients_exp/clients_exp"
import Features from "../sections/features/features"
import CallToAction from "../sections/call_to_action/call_to_action"
import Faq from "../sections/faq/faq"
import AppSlides from "../sections/app_slides/app_slides"
import RecentWork from "../sections/works/recentWork"
import * as works_styles from "../sections/works/works_index.module.scss"
import * as hero_form_styles from "../sections/hero/hero_with_form/hero_with_form.module.scss"
import * as client_exp_styles from "../sections/clients_exp/clients_exp.module.scss"
import * as features_inline_5_pink from "../sections/features/features_inline_5_pink.module.scss"
import * as book_call from "../sections/call_to_action/book_v2.module.scss"
import * as faq_styles from "../sections/faq/faq.module.scss"
import * as app_slides_styles from "../sections/app_slides/app_slides.module.scss"
import { getSeo } from "../utils/seo"
import FitnessAppData from "../utils/fitnessApp-queries"
import app from "../images/app.png"


export default function FitnessApp() {
  const {
    hero,
    works,
    action,
    appSlides,
    testimonial,
    features2,
    faq,
  } = FitnessAppData()

 const heroFormContent = {
    title: "",
    description:
      "Drop your details below and one of our developer wizards will organise a quick call to chat about your app idea!",
    url: "",
  }
  return (
    <Layout navBG={hero?.backgroundColor ? hero.backgroundColor.hex : ""}
      noMenuNavbar={true}
      hideFloatBtn={true}
      noContentFooter={true}
    >
      <HeroWithForm
        hero={hero}
        styles={hero_form_styles}
        hintsFontSize="20px"
        heroFormContent={heroFormContent}
      />
      <Features features={features2} styles={features_inline_5_pink} />
      <RecentWork works={works} styles={works_styles} headerCenter={true} />
      <AppSlides content={appSlides} styles={app_slides_styles} />
      <CallToAction call={action} styles={book_call} isRequestMockup={true} />
      <ClientExp content={testimonial} styles={client_exp_styles} />
      <Faq faq={faq} styles={faq_styles} />
    </Layout>
  )
}

export const Head = () => {
  const { seo } = FitnessAppData()
  return getSeo(seo)
}
