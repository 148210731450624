import React from "react"
import * as styles from "./listItem.module.scss"
import Arrow from "../../../assets/faq_arrow.svg"

export default function ListItem(props) {
  const { question } = props

  return (
    <div className={styles.container}>
      <input
        style={{ height: "100%", position: "absolute", width: "100%" }}
        type="checkbox"
        id={question.id}
        name={question.id}
      />
      {/* <span className={styles.number}>{props.question.number}</span> */}
      <label htmlFor={question.id} className={styles.text}>
        {question.faq}
      </label>
      <Arrow className={styles.arrow} />
      <div className={styles.content}>{question.response}</div>
    </div>
  )
}
