// extracted by mini-css-extract-plugin
export var action = "book_v2-module--action--FIIDp";
export var appSlider = "book_v2-module--app-slider--1d_Ar";
export var callContainer = "book_v2-module--call-container--3_72E";
export var contentWrapper = "book_v2-module--content-wrapper--2ifGQ";
export var defaultContainer = "book_v2-module--default-container--WzI-j";
export var description = "book_v2-module--description--unZAv";
export var featureSlider = "book_v2-module--feature-slider--lQuqi";
export var header = "book_v2-module--header--2Dx7Z";
export var imageContent = "book_v2-module--image-content--2Q7h8";
export var innerPagesSlider = "book_v2-module--inner-pages-slider--qvEgT";
export var leftAppSlide = "book_v2-module--left-app-slide--3unLO";
export var logoSlider = "book_v2-module--logo-slider--Vm4Ex";
export var phoneAppSelect = "book_v2-module--phone-app-select--2gaBa";
export var phoneCaseSelect = "book_v2-module--phone-case-select--2iGPS";
export var rightAppSlide = "book_v2-module--right-app-slide--2gqXj";
export var slickActive = "book_v2-module--slick-active--2X8Vq";
export var slickArrow = "book_v2-module--slick-arrow--3n9qR";
export var slickCenter = "book_v2-module--slick-center--1bDwN";
export var slickDots = "book_v2-module--slick-dots--E24NX";
export var slickNext = "book_v2-module--slick-next--1aLzd";
export var slickPrev = "book_v2-module--slick-prev--37BL2";
export var slickSlide = "book_v2-module--slick-slide--RH84y";
export var slickSlider = "book_v2-module--slick-slider--54L2f";
export var slickTrack = "book_v2-module--slick-track--1QWOQ";
export var solvingSlider = "book_v2-module--solving-slider--2IJ-F";