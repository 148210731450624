// extracted by mini-css-extract-plugin
export var appSlider = "hero_with_form-module--app-slider--eoP1_";
export var contentWrapper = "hero_with_form-module--content-wrapper--3hCgh";
export var defaultContainer = "hero_with_form-module--default-container--3AfpG";
export var featureSlider = "hero_with_form-module--feature-slider--2t6GR";
export var float = "hero_with_form-module--float--2aqrK";
export var formContent = "hero_with_form-module--form-content--1G5Hz";
export var hat = "hero_with_form-module--hat--1tOxE";
export var hero = "hero_with_form-module--hero--3FvZs";
export var heroArrow = "hero_with_form-module--hero-arrow--pb3Je";
export var heroContainer = "hero_with_form-module--hero-container--2HNn6";
export var heroFloatingBtn = "hero_with_form-module--hero-floating-btn--2Q9jC";
export var heroFooter = "hero_with_form-module--hero-footer--3ABJT";
export var heroHeader = "hero_with_form-module--hero-header--1lLby";
export var heroHintWrapper = "hero_with_form-module--hero-hint-wrapper--1dsrL";
export var heroWrapper = "hero_with_form-module--hero-wrapper--2w1sw";
export var imageContent = "hero_with_form-module--image-content--3py24";
export var innerPagesSlider = "hero_with_form-module--inner-pages-slider--RnRTn";
export var leftAppSlide = "hero_with_form-module--left-app-slide--ck402";
export var logoSlider = "hero_with_form-module--logo-slider--cDPlX";
export var phoneAppSelect = "hero_with_form-module--phone-app-select--1aKDu";
export var phoneCaseSelect = "hero_with_form-module--phone-case-select--3EH8r";
export var rightAppSlide = "hero_with_form-module--right-app-slide--2ZQ3l";
export var slickActive = "hero_with_form-module--slick-active--19o_M";
export var slickArrow = "hero_with_form-module--slick-arrow--3jLNK";
export var slickCenter = "hero_with_form-module--slick-center--2tp7R";
export var slickDots = "hero_with_form-module--slick-dots--3hQXM";
export var slickNext = "hero_with_form-module--slick-next--1KiG_";
export var slickPrev = "hero_with_form-module--slick-prev--30DCx";
export var slickSlide = "hero_with_form-module--slick-slide--3hJMF";
export var slickSlider = "hero_with_form-module--slick-slider--LKezK";
export var slickTrack = "hero_with_form-module--slick-track--2g0zS";
export var solvingSlider = "hero_with_form-module--solving-slider--3e0oU";
export var titleWrapper = "hero_with_form-module--title-wrapper--1sba0";