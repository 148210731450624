import React, {useState, useEffect} from "react"
import AppSlideItem from "../../components/app_slide_item/app_slide_item"
import { replaceSpacing } from "../../utils/functions"
import SliderActioner from "../../components/sliderActioner/sliderActioner"
import { useMediaQuery } from "react-responsive"

import {
  animationSlideUp,
  animationSlideRight,
  animationSlideLeft,
  animationFade,
  animationZoomIn,
} from "../../utils/scroll-animation"
import Hints from "../../common/hints/hints"

export default function AppSlides(props) {

  const { content, styles } = props
  const [appName,setAppName] = useState();
  const [appInfo,setAppInfo] = useState();
  const [isAppSlides3,setIsAppSlides3] = useState();
  const isMobile = useMediaQuery({ maxWidth: 480 })

  var sliderSetting = {
    dots: true,
    arrows: true,
    centerMode: content.appSlides.length > 3 ? true : false,
    infinite: true,
    speed: 500,
    slidesToShow: content.appSlides.length > 3 ? 3 : 1,
    centerPadding: "100px",
    onInit: () => {
      if(!isMobile && content.appSlides.length > 3 ? true : false){
        setTimeout(() => {
          let slideChildren = document.querySelectorAll('.app-slider .slick-slide');
      
          for (let i = 0; i < slideChildren.length; i++) {
            let el = slideChildren[i];
            let slideIdx = el.getAttribute('data-index');
    
            if(slideIdx == -2){
              el.classList.add("left-app-slide");
            }
            if(slideIdx == 2){
              el.classList.add("right-app-slide");
            }
          }
        },300)
        
      }
    },
    // afterChange: (Index) => {
    //   setActiveSlideIndex(Index)
    //   if(!isMobile && content.appSlides.length > 3 ? true : false){
    //     let slideChildren = document.querySelectorAll('.app-slider .slick-slide');
    //     let leftItemIdx = Index - 2
    //     let rightItemIdx = Index + 2
  
    //     for (let i = 0; i < slideChildren.length; i++) {
    //       let el = slideChildren[i];
    //       let slideIdx = el.getAttribute('data-index');
  
    //       el.classList.remove("left-app-slide");
    //       el.classList.remove("right-app-slide");
  
    //       if(slideIdx == leftItemIdx.toString()){
    //         el.classList.add("left-app-slide");
    //       }
    //       if(slideIdx == rightItemIdx.toString()){
    //         el.classList.add("right-app-slide");
    //       }
    //     }
    //   }
    // },
    beforeChange: (oldIndex, newIndex) => {
      setActiveSlideIndex(newIndex)
      setTimeout(() => {
        if(!isMobile && content.appSlides.length > 3 ? true : false){
          let slideChildren = document.querySelectorAll('.app-slider .slick-slide');
          let leftItemIdx = newIndex - 2
          let rightItemIdx = newIndex + 2
          
          for (let i = 0; i < slideChildren.length; i++) {
            let el = slideChildren[i];
            let slideIdx = el.getAttribute('data-index');
    
            el.classList.remove("left-app-slide");
            el.classList.remove("right-app-slide");
    
            if(slideIdx == leftItemIdx.toString()){
              el.classList.add("left-app-slide");
            }
            if(slideIdx == rightItemIdx.toString()){
              el.classList.add("right-app-slide");
            }
          }
        }
      },150)
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: content.appSlides.length > 3 ? 3 : 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          centerPadding: "0px",
        }
      }
    ]
  }

  useEffect(() => {
    setIsAppSlides3(content.appSlides.length > 3 ? true : false)
    setAppName(content.appSlides[0].appName);
    setAppInfo(content.appSlides[0].appInfo);
  },[])

  const setActiveSlideIndex = (index) => {
    setAppName(content.appSlides[index].appName);
    setAppInfo(content.appSlides[index].appInfo);
  }

  return (
    <section
      style={{
        backgroundColor: content?.backgroundColor
          ? content.backgroundColor.hex
          : "",
      }}
      className={styles.appSlides}
    >
      <div className="default-container">
        <div className={styles.appSlidesContainer}>
          <div className={styles.titleWrapper}>
            <h2 className={styles.title} {...animationSlideRight()}>
              {replaceSpacing(content?.title)}
            </h2>
            <h3 className={styles.subtitle}>
              {appName}
            </h3>
            <p className={styles.titleInfo}>
              {appInfo}
            </p>
          </div>
          <div className={styles.contentWrapper}>
            <div className={styles.slides}>
              <SliderActioner
                sliderClass={content.appSlides.length > 3 ? "app-slider" : "app-slider-single"}
                sliderSetting={sliderSetting}
                sliderArrowV2={true}
              >
                {content.appSlides.map((slide, index) => (
                  <AppSlideItem
                    key={index}
                    content={slide}
                    book={props.book}
                    setBook={props.setBook}
                    isAppSlides3={isAppSlides3}
                    freeEstimationPopup={props.freeEstimationPopup}
                    setFreeEstimationPopup={props.setFreeEstimationPopup}
                  />
                ))}
              </SliderActioner>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
