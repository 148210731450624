import React, { useState, useEffect } from "react"
import * as styles from "./inquirer_form.module.scss"
import Form from "./form/form"
import Loader from "../../common/loader/loader"
import { navigate } from "gatsby"

export default function InquirerForm(props) {
  const { content } = props
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (completed) {
      // navigate(content.url)
    }
  }, [completed])

  return (
    <div className={styles.inquirerFormContainer}>
      <div className={styles.inquirerFormWrapper}>
        {loading ? (
          <Loader />
        ) : (
          <div className={styles.form}>
            {content?.title && (
              <h2 className={styles.heading}>{content?.title}</h2>
            )}
             {content?.description && (
              <p className={styles.content}>{content?.description}</p>
            )}
            <Form
              {...{
                loading,
                setLoading,
                setCompleted,
                error,
                setError,
                success,
                setSuccess
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}
