import { navigate } from "gatsby"
import React from "react"
import * as styles from "./app_slide_item.module.scss"
import PhoneCase from "../../images/phone-mockup.png"
 
export default function AppSlideItem(props) {
  const { content, isAppSlides3 } = props

  const resolve = action => {
    if (action == null) return
    console.log(action)
    switch (action) {
      case "quote": {
        props.setBook(true)
        break
      }
      case "estimate": {
        props.setFreeEstimationPopup(true)
        break
      }
      case "navigate": {
        if (content.link == null) return
        navigate(`/${content.link}`)
        break
      }
    }
  }
  return (
    <div className={ isAppSlides3 ? styles.content : styles.singleDisplay }>
      <img className={`phone-case-select ${styles.phoneCase}`} src={PhoneCase} alt=""></img>
      <img className={`phone-app-select ${styles.phoneApp}`}  src={content.appImage.asset.url} alt=""></img>
    </div>
  )
}

