// extracted by mini-css-extract-plugin
export var appSlider = "listItem-module--app-slider--1Z7gt";
export var arrow = "listItem-module--arrow--38IYe";
export var container = "listItem-module--container--1MDof";
export var content = "listItem-module--content--1Ymd6";
export var defaultContainer = "listItem-module--default-container--1LJYh";
export var featureSlider = "listItem-module--feature-slider--P5ffN";
export var innerPagesSlider = "listItem-module--inner-pages-slider---Sd7B";
export var leftAppSlide = "listItem-module--left-app-slide--1dKhJ";
export var logoSlider = "listItem-module--logo-slider--FfUrn";
export var number = "listItem-module--number--3Bl9x";
export var phoneAppSelect = "listItem-module--phone-app-select--fOxC-";
export var phoneCaseSelect = "listItem-module--phone-case-select--24QUy";
export var rightAppSlide = "listItem-module--right-app-slide--1hf_8";
export var slickActive = "listItem-module--slick-active--1XWUd";
export var slickArrow = "listItem-module--slick-arrow--13nN6";
export var slickCenter = "listItem-module--slick-center--23LH6";
export var slickDots = "listItem-module--slick-dots--wb7wS";
export var slickNext = "listItem-module--slick-next---Qny5";
export var slickPrev = "listItem-module--slick-prev--2VZak";
export var slickSlide = "listItem-module--slick-slide--2NPZh";
export var slickSlider = "listItem-module--slick-slider--2tJ3E";
export var slickTrack = "listItem-module--slick-track--270Sj";
export var solvingSlider = "listItem-module--solving-slider--2bpyk";
export var text = "listItem-module--text--3VUPl";