// extracted by mini-css-extract-plugin
export var appSlider = "clients-module--app-slider--24qDt";
export var clientsItem = "clients-module--clients-item--23DCd";
export var content = "clients-module--content--sBXDR";
export var defaultContainer = "clients-module--default-container--2Wz6t";
export var featureSlider = "clients-module--feature-slider--OxR8I";
export var head = "clients-module--head--U0wGh";
export var innerPagesSlider = "clients-module--inner-pages-slider--28hiE";
export var leftAppSlide = "clients-module--left-app-slide--5qhjO";
export var logoSlider = "clients-module--logo-slider--2o6Zs";
export var phoneAppSelect = "clients-module--phone-app-select--2wARc";
export var phoneCaseSelect = "clients-module--phone-case-select--3LPNH";
export var rightAppSlide = "clients-module--right-app-slide--3NxjX";
export var slickActive = "clients-module--slick-active--3Zi66";
export var slickArrow = "clients-module--slick-arrow--1hX9b";
export var slickCenter = "clients-module--slick-center--1vcsm";
export var slickDots = "clients-module--slick-dots--32fHj";
export var slickNext = "clients-module--slick-next--3bPVj";
export var slickPrev = "clients-module--slick-prev--3rwE_";
export var slickSlide = "clients-module--slick-slide--3SMir";
export var slickSlider = "clients-module--slick-slider--3iOQh";
export var slickTrack = "clients-module--slick-track--29Fjz";
export var solvingSlider = "clients-module--solving-slider--222cD";
export var techs = "clients-module--techs--3obvo";