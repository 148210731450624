// extracted by mini-css-extract-plugin
export var aboutContainer = "features_inline_5_pink-module--about-container--2KtYw";
export var aboutContent = "features_inline_5_pink-module--about-content--k_qXS";
export var appSlider = "features_inline_5_pink-module--app-slider--2ExWz";
export var contentWrapper = "features_inline_5_pink-module--content-wrapper--1hoje";
export var defaultContainer = "features_inline_5_pink-module--default-container--1aIpa";
export var featureSlider = "features_inline_5_pink-module--feature-slider--1CcAh";
export var features = "features_inline_5_pink-module--features---bptQ";
export var featuresItem = "features_inline_5_pink-module--features-item--2lwYM";
export var infoBoxImage = "features_inline_5_pink-module--info-box-image--1pt2W";
export var innerPagesSlider = "features_inline_5_pink-module--inner-pages-slider--3jorD";
export var leftAppSlide = "features_inline_5_pink-module--left-app-slide--1Edxj";
export var logoSlider = "features_inline_5_pink-module--logo-slider--SHIZl";
export var numbers = "features_inline_5_pink-module--numbers--20UDA";
export var phoneAppSelect = "features_inline_5_pink-module--phone-app-select--2Krlr";
export var phoneCaseSelect = "features_inline_5_pink-module--phone-case-select--_KCdJ";
export var rightAppSlide = "features_inline_5_pink-module--right-app-slide--2lpZR";
export var slickActive = "features_inline_5_pink-module--slick-active--3g-Nz";
export var slickArrow = "features_inline_5_pink-module--slick-arrow--2Phdk";
export var slickCenter = "features_inline_5_pink-module--slick-center--1uXq-";
export var slickDots = "features_inline_5_pink-module--slick-dots--215BD";
export var slickNext = "features_inline_5_pink-module--slick-next--2yv-8";
export var slickPrev = "features_inline_5_pink-module--slick-prev--1eobx";
export var slickSlide = "features_inline_5_pink-module--slick-slide--3n7Bo";
export var slickSlider = "features_inline_5_pink-module--slick-slider--1PBw1";
export var slickTrack = "features_inline_5_pink-module--slick-track--2Dd_M";
export var solvingSlider = "features_inline_5_pink-module--solving-slider--2VMIS";
export var title = "features_inline_5_pink-module--title--wSBfi";
export var titleWrapper = "features_inline_5_pink-module--title-wrapper--15I2B";