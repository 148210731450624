// extracted by mini-css-extract-plugin
export var appSlider = "faq-module--app-slider--3OsFU";
export var content = "faq-module--content--TR7iw";
export var contentWrapper = "faq-module--content-wrapper--27j-g";
export var defaultContainer = "faq-module--default-container--yThhQ";
export var faq = "faq-module--faq--2ghvD";
export var faqContainer = "faq-module--faq-container--3utW6";
export var featureSlider = "faq-module--feature-slider--1UZko";
export var innerPagesSlider = "faq-module--inner-pages-slider--PZbWP";
export var leftAppSlide = "faq-module--left-app-slide--pij3C";
export var logoSlider = "faq-module--logo-slider--1NDr7";
export var phoneAppSelect = "faq-module--phone-app-select--3CaFI";
export var phoneCaseSelect = "faq-module--phone-case-select--3UIz8";
export var rightAppSlide = "faq-module--right-app-slide--1FGvz";
export var slickActive = "faq-module--slick-active--3GdD8";
export var slickArrow = "faq-module--slick-arrow--2NabG";
export var slickCenter = "faq-module--slick-center--3WOqB";
export var slickDots = "faq-module--slick-dots--1Nmiv";
export var slickNext = "faq-module--slick-next--ZBh9L";
export var slickPrev = "faq-module--slick-prev--3qlCH";
export var slickSlide = "faq-module--slick-slide--31mrI";
export var slickSlider = "faq-module--slick-slider--3TtEP";
export var slickTrack = "faq-module--slick-track--1YHoQ";
export var solvingSlider = "faq-module--solving-slider--7cVLC";
export var title = "faq-module--title--1hfr1";
export var titleWrapper = "faq-module--title-wrapper--1J-A1";