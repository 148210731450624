import React, { useState } from "react"
import * as styles from "./form.module.scss"
import { inquirerSchema } from "../../../utils/validation/inquirerValidator"
import { useForm } from "react-hook-form"
import successImage from "../../../images/tick.gif"
import { yupResolver } from "@hookform/resolvers/yup"
import axios from "axios"
import { gtagTraking } from "../../../utils/gtagTraking"

export default function Form(props) {
  const { completed, setCompleted, setLoading, loading, error, setError, success, setSuccess } = props

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(inquirerSchema),
  })

  const submit = async data => {
    setError("")
    setLoading(true)

    let headersList = {
      "Content-Type": "application/json",
    }

    let bodyContent = JSON.stringify({
      data,
    })

    let reqOptions = {
      url: `/.netlify/functions/inquire`,
      method: "POST",
      headers: headersList,
      data: bodyContent,
    }
    //create client
    return await axios
      .request(reqOptions)
      .then(res => {
        setLoading(false)
        setCompleted(true)
        setSuccess(true)
        gtagTraking(window)

      })
      .catch(error => {
        let errorMessage = error.response.data
        setLoading(false)
        setSuccess(false)
        if(error.response.status == 504){
          errorMessage = 'Request timed out. Please try again.'
        }
        setError(errorMessage)
        console.log(error)
      })
  }

  return (
    <div className={styles.container}>
      <form
        id="inquire"
        className={styles.form}
        onSubmit={handleSubmit(data => submit(data))}
      >
        <label className={styles.halfWidth}>
          <input
            {...register("firstName")}
            type="text"
            name="firstName"
            placeholder="First Name"
          />
          {errors.firstName && (
            <p className={styles.error}>First name is required</p>
          )}
        </label>
        <label className={styles.halfWidth}>
          <input
            {...register("lastName")}
            type="text"
            name="lastName"
            placeholder="Last Name"
          />
          {errors.lastName && (
            <p className={styles.error}>Last name is required</p>
          )}
        </label>
        <label>
          <input
            {...register("email")}
            type="email"
            name="email"
            placeholder="Email Address"
          />
          {errors.email && (
            <p className={styles.error}>Email is required</p>
          )}
        </label>
        <label>
          <input
            {...register("phoneNumber")}
            type="number"
            name="phoneNumber"
            placeholder="Phone Number"
          />
          {errors.phoneNumber && (
            <p className={styles.error}>Please enter a valid phone number</p>
          )}
        </label>
      </form>
      <button
        type="submit"
        form="inquire"
        className={styles.button}
        disabled={loading}
      >
        Let’s start the magic!
      </button>
      {error !== "" && <p className={styles.error}>{error}</p>}
      {success && (
        <div className={styles.success}>
          <img src={successImage} />
          <p>Successfully submitted!</p>
        </div>
      )}
    </div>
  )
}
